import * as React from 'react';

import CTA from '../components/CTA';

import '../styles/careersCta.less';

const CareersCTA = (): JSX.Element => {
    return (
        <div className="careers-cta">
            <h4 className="careers-cta__title">Careers</h4>
            <p className="careers-cta__description">If you’re interested in working with leaders in video streaming technology then we’d love to hear from you.</p>
            <CTA classname="primary" text="Review open positions" path="/careers/" />
        </div>
    );
};

export default CareersCTA;
