import * as React from 'react';
import { Helmet } from 'react-helmet';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from '../components/Layout';
import CareersCTA from '../components/CareersCTA';

import '../styles/aboutUs.less';

const AboutUsPage = (): JSX.Element => {
    return (
        <>
            <Helmet title="About Us" />
            <Layout classname="about-us">
                <div className="page page__lg">
                    <div className="pic-block">
                        <div className="pic-block__text">
                            <h1 className="page__title no-padding">CC Media Network Ltd was founded in 1999. The company is now based in Gibraltar and since 2018 has been owned by the current management team.</h1>
                            <h2 className="page__subtitle">Virtual and flexible work teams.</h2>
                            <p>Our wider team has been virtual for six years, with people collaborating online from countries across the globe. We are highly adept at working remotely, using all the available technologies.</p>
                            <h2 className="page__subtitle">Enhanced work-life balance</h2>
                            <p>The virtual approach provides for great flexibility, and in particular allows everyone an enhanced work-life balance. Also, it allows us to hire the best people across geographies.</p>
                        </div>
                        <div className="pic-block__img">
                            <StaticImage src="../images/bg-about-1.png" alt="CC Media Network Ltd was founded in 1999" />
                        </div>
                    </div>

                    <div className="page__cnt">
                        <h3 className="page__title page__title--alt">CC Media Network Ltd is committed to ensuring that any business undertakings are conducted as ethically as possible.</h3>
                    </div>

                    <div className="pic-block pic-block--ltr">
                        <div className="pic-block__img">
                            <StaticImage src="../images/bg-about-2.png" alt="Looking after customers" />
                        </div>
                        <div className="pic-block__text">
                            <h4 className="page__subtitle page__subtitle--alt">Looking after customers</h4>
                            <ul className="list">
                                <li>We provide excellent customer service – we are highly responsive and very helpful</li>
                                <li>We make our product pricing completely transparent</li>
                                <li>We act on all feedback received from customers with the aim of making our platforms as user-friendly and highly-functioning as possible</li>
                            </ul>
                            <h4 className="page__subtitle page__subtitle--alt">Looking after partners and suppliers</h4>
                            <ul className="list">
                                <li>We provide excellent partner and supplier communication and we are open, honest and clear in all our relationships</li>
                                <li>We pay everyone on or before the date agreed</li>
                            </ul>
                        </div>
                    </div>
                    <div className="pic-block pic-block--rtl">
                        <div className="pic-block__text">
                            <h4 className="page__subtitle page__subtitle--alt">Looking after our team</h4>
                            <ul className="list list--alt">
                                <li>We are very clear with our team as to what is expected of them</li>
                                <li>We work collaboratively and collegiately to ensure all members of the team feel involved and have the chance to grow and develop</li>
                                <li>We provide whatever training is required to enable them to excel in their roles</li>
                                <li>We pay competitively</li>
                            </ul>
                            <h4 className="page__subtitle page__subtitle--alt">The environment</h4>
                            <ul className="list list--alt">
                                <li>The team work at home, thus both negating all the environmental impact that having offices entails and improving work-life balance</li>
                                <li>We travel as little as possible</li>
                            </ul>
                        </div>
                        <div className="pic-block__img">
                            <StaticImage src="../images/bg-about-3.png" alt="Looking after our team" />
                        </div>
                    </div>
                    <CareersCTA />
                </div>
            </Layout>
        </>
    );
};

export default AboutUsPage;
